define("ember-cli-notifications/components/notification-message", ["exports", "@ember/component", "@ember/template", "@ember/object", "@ember/service", "ember-cli-notifications/templates/components/notification-message"], function (_exports, _component, _template, _object, _service, _notificationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const availableTypes = ['info', 'success', 'warning', 'error'];
  let NotificationMessage = _exports.default = (_dec = (0, _object.computed)('notification.dismiss'), _dec2 = (0, _object.computed)('notification.onClick'), _dec3 = (0, _object.computed)('notification.type'), _dec4 = (0, _object.computed)('validType'), _dec5 = (0, _object.computed)('validType'), _dec6 = (0, _object.computed)('validType'), _dec7 = (0, _object.computed)('validType'), _dec8 = (0, _object.computed)('paused', 'notification.clearDuration'), (_class = class NotificationMessage extends _component.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _notificationMessage.default);
      _defineProperty(this, "tagName", '');
      _initializerDefineProperty(this, "notifications", _descriptor, this);
      _defineProperty(this, "paused", false);
    }
    get dismissClass() {
      return !this.notification.dismiss ? 'c-notification--in' : '';
    }
    get clickableClass() {
      return this.notification.onClick ? 'c-notification--clickable' : '';
    }
    get validType() {
      let type = this.notification.type;
      if (!type || availableTypes.indexOf(type) === -1) {
        return '';
      }
      return type;
    }
    get isInfo() {
      return this.validType === 'info';
    }
    get isSuccess() {
      return this.validType === 'success';
    }
    get isWarning() {
      return this.validType === 'warning';
    }
    get isError() {
      return this.validType === 'error';
    }

    // Apply the clear animation duration rule inline
    get notificationClearDuration() {
      const duration = Number.parseInt(this.notification.clearDuration, 10);
      const playState = this.paused ? 'paused' : 'running';
      const styles = [`animation-duration: ${duration}ms`, `animation-play-state: ${playState}`].join(';');
      return (0, _template.htmlSafe)(styles);
    }
    handleOnClick(event) {
      event.preventDefault();
      this.notification.onClick?.(this.notification);
    }
    removeNotification(event) {
      event.preventDefault();
      event.stopPropagation();
      this.notifications.removeNotification(this.notification);
    }
    handleMouseEnter() {
      if (this.notification.autoClear) {
        (0, _object.set)(this, 'paused', true);
        this.notifications.pauseAutoClear(this.notification);
      }
    }
    handleMouseLeave() {
      if (this.notification.autoClear) {
        (0, _object.set)(this, 'paused', false);
        this.notifications.setupAutoClear(this.notification);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dismissClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dismissClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickableClass", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clickableClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validType", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "validType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isInfo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isSuccess", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isSuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "isWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isError", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notificationClearDuration", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "notificationClearDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeNotification", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeNotification"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype)), _class));
});